import { get, put, post, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateHash } from '../../../assets/scripts/utilsLib';
// import config from '../../../config';
// import { promisify } from 'util';

/**
 * Creates a basic post and saves to the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @returns {Promise} http message response promise
 */
export const createBasePost = async (appId, socialGroupId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post`,
            options: {
                headers: {
                    Authorization: token
                }
            },
            signal: AbortSignal.timeout(90000)
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the post from the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @returns {Promise} http message response promise
 */
export const deletePost = async (appId, socialGroupId, postId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes the social media master post from the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId socialPostCode of the master post to remove
 * @returns {Promise} http message response promise
 */
export const deleteMasterPost = async (appId, socialGroupId, masterPostId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets the details of the post
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @returns {Promise} http message response promise
 */
export const getPost = async (appId, socialGroupId, postId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets the details of the master post
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId unquie id for the share to remove
 * @returns {Promise} http message response promise
 */
export const getMasterPost = async (appId, socialGroupId, masterPostId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Add a new social media post to the selected profiles
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @param {object} data post object
 * @returns {Promise} http message response promise
 */
export const savePost = async (appId, socialGroupId, postId, data) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    identitys: data.identitys,
                    message: data.message,
                    scheduledPostDate: data.scheduledPostDate,
                    activityType: data.activityType,
                    postStatus: data.postStatus,
                    shareUrl: data.shareUrl,
                    mediaUrl: data.mediaUrl,
                    media: { ...data.media },
                    remoteSource: { ...data.remoteSource },
                    albumId: data.albumId,
                    tags: data.tags,
                    contentTypes: data.contentType
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves a master social media post to the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId socialPostCode of the share to save
 * @param {object} data post object
 * @returns {Promise} http message response promise
 */
export const saveMasterPost = async (appId, socialGroupId, masterPostId, data) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    identitys: data.identitys,
                    message: data.message,
                    scheduledPostDate: data.scheduledPostDate,
                    activityType: data.activityType,
                    postStatus: data.postStatus,
                    shareUrl: data.shareUrl,
                    mediaUrl: data.mediaUrl,
                    media: { ...data.media },
                    remoteSource: { ...data.remoteSource },
                    albumId: data.albumId,
                    tags: data.tags
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Send the selected media file to S3
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} socialPostCode post id of the share to upload the image to
 * @param {File} file file object to upload
 * @param {object} fileInfo file metadata
 * @returns {Promise} promise containing the upload response
 */
export const uploadMasterMedia = async (appId, socialGroupId, socialPostCode, file, fileInfo) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const extension = fileInfo.type.split('/')[1];

        // greater than 10 MB
        if (fileInfo.size >= 10485760) {
            const hash = await generateHash(file);

            await post({
                apiName: 'stitchz-web-api',
                path: `/app/${appId}/social/${socialGroupId}/post/${socialPostCode}/signed`,
                options: {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        contentType: fileInfo.type,
                        extension: extension,
                        height: fileInfo.height,
                        width: fileInfo.width,
                        size: fileInfo.size,
                        format: fileInfo.format,
                        fileName: fileInfo.name,
                        hash: hash
                    }
                }
            })
                .then(async body => {
                    const result = await body.json();
                    // console.log(`Body: ${JSON.stringify(result.body)}`);
                    let payload = {
                        'method': 'PUT',
                        'headers': {
                            'content-type': fileInfo.type,
                            'content-length': file.size
                        },
                        'body': file
                    };
                    // console.log(payload);
                    return await fetch(result.body.uploadURL, payload)
                        .then(response => {
                            // console.log(response);
                            return {
                                status: response.statusText,
                                body: {
                                    publicFilePath: result.body.publicFilePath,
                                    width: fileInfo.width,
                                    height: fileInfo.height,
                                    contentType: fileInfo.type,
                                    size: fileInfo.size
                                }
                            };
                        });
                });
        } else {
            // base64 encode the file then upload to the API Gateway
            const base64Data = await readUploadedFile(file)
                .catch(err => {
                    console.error(err);
                    return null;
                });

            let url = `/app/${appId}/social/${socialGroupId}/post/master/${socialPostCode}/media`;

            if (fileInfo.type.indexOf('video/mp4') > -1) {
                url = `/app/${appId}/social/${socialGroupId}/post/master/${socialPostCode}/video`
            }

            const { body } = await post({
                apiName: 'stitchz-web-api',
                path: url,
                options: {
                    headers: {
                        Authorization: token
                    },
                    isBase64Encoded: true,
                    body: {
                        file: base64Data
                    }
                }
            }).response;

            return await body.json();
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Send the selected media file to S3
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {File} file file object to upload
 * @param {object} fileInfo file metadata
 * @returns {Promise} promise containing the upload response
 */
export const uploadMedia = async (appId, socialGroupId, postId, file, fileInfo) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const extension = fileInfo.type.split('/')[1];

        // greater than 10 MB
        if (fileInfo.size >= 10485760) {
            const hash = await generateHash(file);

            await post({
                apiName: 'stitchz-web-api',
                path: `/app/${appId}/social/${socialGroupId}/post/${postId}/signed`,
                options: {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        contentType: fileInfo.type,
                        extension: extension,
                        height: fileInfo.height,
                        width: fileInfo.width,
                        size: fileInfo.size,
                        format: fileInfo.format,
                        fileName: fileInfo.name,
                        hash: hash
                    }
                }
            })
                .then(async body => {
                    const result = await body.json();
                    // console.log(`Body: ${JSON.stringify(result.body)}`);
                    let payload = {
                        'method': 'PUT',
                        'headers': {
                            'content-type': fileInfo.type,
                            'content-length': file.size
                        },
                        'body': file
                    };
                    // console.log(payload);
                    return await fetch(result.body.uploadURL, payload)
                        .then(response => {
                            // console.log(response);
                            return {
                                status: response.statusText,
                                body: {
                                    publicFilePath: result.body.publicFilePath,
                                    width: fileInfo.width,
                                    height: fileInfo.height,
                                    contentType: fileInfo.type,
                                    size: fileInfo.size
                                }
                            };
                        });
                });
        } else {
            // base64 encode the file then upload to the API Gateway
            const base64Data = await readUploadedFile(file)
                .catch(err => {
                    console.error(err);
                    return null;
                });

            let url = `/app/${appId}/social/${socialGroupId}/post/${postId}/media`;

            if (fileInfo.type.indexOf('video/mp4') > -1) {
                url = `/app/${appId}/social/${socialGroupId}/post/${postId}/video`
            }

            const { body } = await post({
                apiName: 'stitchz-web-api',
                path: url,
                options: {
                    headers: {
                        Authorization: token
                    },
                    isBase64Encoded: true,
                    body: {
                        file: base64Data
                    }
                }
            }).response;

            return await body.json();
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Delete a given media file from the associated S3 bucket
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} socialPostCode post id of the share to upload the image to
 * @param {string} key name of media item to delete
 * @returns {void}
 */
export const deleteMasterMedia = async (appId, socialGroupId, socialPostCode, key) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/master/${socialPostCode}/media/${key}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Delete a given media file from the associated S3 bucket
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {string} key name of media item to delete
 * @returns {void}
 */
export const deleteMedia = async (appId, socialGroupId, postId, key) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}/media/${key}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Queries the remote url and returns a list of images and their respective content
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {object} url valid url to search
 * @returns {Promise} http message response promise
 */
export const getRemoteUrl = async (appId, socialGroupId, postId, url) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken,
            endpoint = `/app/${appId}/social/${socialGroupId}/post/${postId}/remote?url=${encodeURIComponent(url)}`;

        const result = await get({
            apiName: 'stitchz-web-api',
            path: endpoint,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        let responseBody = await result.body.json();
        responseBody.statusCode = result.statusCode;

        return responseBody;
    } catch (e) {
        console.log('Error there...');
        console.error(e);
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Moves the local S3 object to a permanent location
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {string} url local url (S3 key) or object selected
 * @param {object} remoteSourceObject object containing the remote source details to save
 * @param {boolean} isMaster whether this is a master post or not
 * @returns {Promise} http message response promise
 */
export const selectRemoteImage = async (appId, socialGroupId, postId, url, remoteSourceObject, isMaster) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}/remote`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    isMaster: isMaster,
                    key: url,
                    sourceTitle: remoteSourceObject.sourceTitle,
                    sourceUrl: remoteSourceObject.sourceUrl,
                    sourceAttribution: remoteSourceObject.sourceAttribution,
                    sourceAttributionUrl: remoteSourceObject.sourceAttributionUrl,
                    sourceImageUrl: remoteSourceObject.sourceImageUrl,
                    sourceDescription: remoteSourceObject.sourceDescription
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Change the scheduled post date
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId ID of the post to reschedule
 * @returns {Promise} http message response promise
 */
export const copyPost = async (appId, socialGroupId, postId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}/copy`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts for the given month
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} date date filter in the format of '2020-10' (YYYY-MM)
 * @returns {Promise} http message response promise
 */
export const getPostsByScheduledDate = async (appId, socialGroupId, date) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/list?keyOperator=begins_with&keyFilterBy=scheduledPostDate&keyValue=${date}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts for the given month
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} date date filter in the format of '2020-10' (YYYY-MM)
 * @returns {Promise} http message response promise
 */
export const getPostsByDate = async (appId, socialGroupId, date) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/list?keyOperator=begins_with&keyFilterBy=postDate&keyValue=${date}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts matching the post status given
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} status post status to filter for
 * @param {string} key partition or primary key name
 * @param {string} sort sort by key
 * @returns {Promise} http message response promise
 */
export const getPostsByStatus = async (appId, socialGroupId, status, key, sort) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        if (!sort) {
            sort = 'addedAt';
        }

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/list?keyFilterBy=${key}&keyOperator=begins_with&sortBy=${sort}&filterBy=postStatus&filterValue=${status}&operator=eq`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Add a new social media post to the selected profiles
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @returns {Promise} http message response promise
 */
export const rePost = async (appId, socialGroupId, postId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/post/${postId}/repost`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {}
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * call the polling endpoint and get the response
 * @param {string} apiEndpoint the api endpoint to poll
 * @returns {object} json object of response
 */
export const pollApi = async (apiEndpoint) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        let response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token.toString()}`
            }
        })
            .catch(err => {
                console.log(err);
                return {
                    statusCode: 500,
                    message: err.message
                };
            });
            // console.log(response);

        // get the content type header
        const ctype = response.headers.get('Content-Type');
        // console.log(response.status);
        let responseBody = ctype === 'application/json' ? await response.json() : await response.text();
        responseBody.statusCode = response.status;
        responseBody.contentType = ctype;

        return responseBody;
    } catch (error) {
        console.error('Error making API request:', error);
        return error;
    }
};

/**
 * Read the input file and transform into a buffer array
 * @param {string} inputFile base64 encoded image file
 * @returns {Promise<ArrayBuffer|DOMException>} promise containing the buffer array of the image OR and Error
 */
const readUploadedFile = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
    });
};
