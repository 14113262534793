import React, { useState, useEffect, useCallback, Suspense, ReactComponentElement } from 'react';
import { Container, Row, Col, Form, ButtonGroup, Button, Alert, Card, Navbar } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faLevelDownAlt, faLink, faRedo, faTimesCircle, faSave, faTrash, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getApplicationDetails } from '../../libs/Api/application';
import { getPost, deletePost, savePost, uploadMedia, getRemoteUrl, pollApi, selectRemoteImage, deleteMedia } from '../../libs/Api/posts';
import { initiateNewIdentityRequest, reorderIdentitys, getPagesCompaniesBoards, addPageCompanyIdentity  } from '../../libs/Api/socialgroup';
import { DateTimePicker } from '../../components/Social/Calendar/DateTimePicker';
import { Loading } from '../../../components/Loading';
import { LoaderButton } from '../../../components/LoaderButton';
import { PostIdentityCards } from '../../components/Social/PostIdentityCards';
import { useRouter, useToggle } from '../../../assets/scripts/hooksLib';
import { isValidDateTime, isValidTime, getLocalDateTime, formatDate, setDateTime, addTimeToDate, addTimezoneOffset } from '../../../assets/scripts/dateLib';
import { getTweetLength, scrollTop, nearestAncestorWithId, nearestAncestorWithClassname, nearestAncestorWithHref, adjustHeader, generateRandomString, getErrorMessageTitle, getErrorMessageBody, isString, fileSizeDiplay } from '../../../assets/scripts/utilsLib';
import { sendChatGPTMessage } from '../../libs/Api/chatgpt';
import config from '../../../config';
import '../../../assets/styles/posts.scss';

const AddImageModal = React.lazy(() => import('../../components/Social/AddImageModal'));
const AddRemoteSourceModal = React.lazy(() => import('../../components/Social/AddRemoteSourceModal'));
const KeywordsChatGPTBlock = React.lazy(() => import('../../components/Social/KeywordsChatGPTBlock'));
const ImageChatGPTBlock = React.lazy(() => import('../../components/Social/ImageChatGPTBlock'));
const ErrorModal = React.lazy(() => import('../../components/ErrorMessage'));

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/**
 * Edit post page
 * @returns {HTMLElement} html for edit post block
 */
export default function EditPost() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [post, setPost] = useState();
    const [socialGroup, setSocialGroup] = useState(null);
    const [identity, setIdentity] = useState(null); // eslint-disable-line no-unused-vars
    const [scheduledDateTime, setScheduledDateTime] = useState(null);
    const [isDateTimeValid, setIsDateTimeValid] = useState(false);
    const [dateHasFocus, setDateHasFocus] = useState(false);
    const [timeHasFocus, setTimeHasFocus] = useState(false);
    const [totalCharacters, setTotalCharacters] = useState(0);
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    /**
     * Sets the Error Modal Window with error message and context
     * @param {string} title error message window title
     * @param {string} message error messsage
     * @param {[string]} level (optional) level of severity, e.g. danger, warning, info, etc.
     * @param {[string]} buttonTxt (optional) text label on button
     * @returns {void}
     */
    const setErrorOccurred = (title, message, level='danger', buttonTxt='OK') => {
        setErrorMessage({
            title: title,
            message: message,
            level: level,
            buttonText: buttonTxt
        });
        setShowErrorModal(true);
    };

    /**
     * Switches the Error modal window visibility to true or false
     * @returns {void}
     */
    const showErrorMessageModal = () => {
        if (showErrorModal) {
            setShowErrorModal(false);
            setErrorMessage({});
        } else {
            setShowErrorModal(true);
        }
    };

    /**
     * Notify the end user they are leaving the page
     * @param {Event} e any event object on the page
     * @returns {boolean|void} whether a change has been made in the page or not or nothing at all
     */
    const notifyOnUpdateWithoutSave = (e) => {
        if (e) {
            e.preventDefault();

            return e.returnValue = 'You have made changes to the Post, if you navigate away without saving you will loose your changes';
        }
    };

    /**
     * Add an event listener if any form changes were made
     */
    const setNotifyFormUpdated = useCallback(() => {
        if (hasDataChanged) {
            window.addEventListener('beforeunload', notifyOnUpdateWithoutSave, { capture: true });
        }
    }, [hasDataChanged]);

    // remote url and image modal controls
    const [showAddImageModal, setShowAddImageModal] = useToggle();
    const [showAddRemoteSourceModal, setShowAddRemoteSourceModal] = useToggle();
    const [isRemoteSourceLoading, setIsRemoteSourceLoading] = useState(false);
    const [remoteSourceUrl, setRemoteSourceUrl] = useState(null);
    const [remoteSourceContent, setRemoteSourceContent] = useState(null);
    const [includeImage, setIncludeImage] = useState(true);
    const [loadingImage, setLoadingImage] = useToggle(false);
    const [loadingRemote, setLoadingRemote] = useState(null);
    const [isRefreshing, setIsRefreshing] = useToggle(false);
    const [showChatGPTModal, setShowChatGPTModal] = useToggle();
    const [openAIWords, setOpenAIWords] = useState(null);
    const [isChatGPTLoading, setIsChatGPTLoading] = useState(false);
    const [showImageChatGPTModal, setShowImageChatGPTModal] = useToggle();
    const [openAIImageWords, setOpenAIImageWords] = useState(null);
    const [isChatImageGPTLoading, setIsChatImageGPTLoading] = useState(false);
    const [chatGPTResponse, setChatGPTResponse] = useState(null);

    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    // onError(message);
                    setErrorOccurred('An Error Occurred While Loading Application Info', message);
                } else {
                    setSocialGroup(body.socialGroups.find(sg => {
                        return sg.id === router.query.grpId;
                    }));
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.search}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    // onError(e);
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            } finally {
                // setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router]);

    // load post info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const { status, message, body } = await getPost(router.query.id, router.query.grpId, router.query.postId)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    // onError(message);
                    setErrorOccurred('An Error Occurred While Loading the Post Info', message);
                } else {
                    if (!body || Object.keys(body).length === 0) {
                        // redirect as we didn't find any posts
                        router.history(router.query.ref || `/${router.query.id}/social/${router.query.grpId}/calendar`);
                    } else {
                        // split scheduledPostTime out from scheduledPostDate
                        const scheduledDate = getLocalDateTime(body.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), // eslint-disable-line quotes
                            postDate = getLocalDateTime(body.postDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // eslint-disable-line quotes

                        body.scheduledPostDate = formatDate(scheduledDate, 'dd-MMM-yyyy'); //scheduledDate.format('DD-MMM-YYYY');
                        body.scheduledPostTime = formatDate(scheduledDate, 'HH:mm'); //scheduledDate.format('HH:mm');
                        body.postDate = formatDate(postDate, 'dd-MMM-yyyy HH:mm');

                        setPost(body);
                        setScheduledDateTime(scheduledDate);
                        setRemoteSourceUrl(body.shareUrl);

                        if (body.message.length > 0 &&
                            (body.scheduledPostDate && body.scheduledPostDate.length) &&
                            (body.scheduledPostTime && body.scheduledPostTime.length)) {
                            setIsDateTimeValid(true);
                        }

                        setTotalCharacters(getTweetLength(body.message, body.shareUrl, body.mediaUrl));

                        // set chatgpt suggestions
                        if (body.media && body.media.words) {
                            if (body.media.words !== openAIWords) {
                                setOpenAIImageWords(body.media.words);
                            }
                        }
                    }
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    // onError(e);
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            } finally {
                setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => {
            window.removeEventListener('beforeunload', notifyOnUpdateWithoutSave);
            isMounted = false;
        };
    }, [router]);

    /**
     * Returns true if a post message is entered and there is a scheduled date
     * @returns {boolean} true or false
     */
    const validateForm = () => {
        return post && (post.message && post.message.length > 0) &&
            (post.scheduledPostDate && post.scheduledPostDate.length) &&
            (post.scheduledPostTime && post.scheduledPostTime.length) &&
            isDateTimeValid &&
            checkPinterestBoardSelected() &&
            checkPinterestImageAttachedIsAllowed();
    };

    /**
     * Check if Pinterest is a share selection and if so has a board been selected
     * @returns {boolean} true or false (no board selected)
     */
    const checkPinterestBoardSelected = () => {
        if (socialGroup && socialGroup.socialGroupIdentitys.some(i => { return i.providerId === 27 })) {
            const pinterestBoards = socialGroup.socialGroupIdentitys.filter(i => {
                return post.identityId === i.id && i.providerId === 27;
            });

            if (pinterestBoards.length > 0) {
                return post.albumId > 0;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    /**
     * Check if the attached image is a valid image type on Pinterest
     * @returns {boolean} true or false (no board selected)
     */
    const checkPinterestImageAttachedIsAllowed = () => {
        if (socialGroup && socialGroup.socialGroupIdentitys.some(i => { return i.providerId === 27 })) {
            const media = post.media;
            // ext = media.url.split('.').pop();

            // if (['jpg', 'jpeg', 'png'].indexOf(ext) < 0) {
            if (media && media.contentType && ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4'].indexOf(media.contentType) > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    /**
     * onChange event for the text input to update the application and refreshes the state
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        if (e && e.target) {
            const field = e.target.id,
                val = e.target.value;

            // check if date/time are valid entries
            if (isValidDateTime(scheduledDateTime)) {
                setIsDateTimeValid(true);
            }

            // set the UI counter
            if (field === 'message') {
                setHasDataChanged(true);
                setNotifyFormUpdated();
                // count Twitter characters
                setTotalCharacters(getTweetLength(val, post.shareUrl, post.mediaUrl));
            }

            setPost(post => {
                return { ...post, [field]: val }
            });
        }
    };

    /**
     * onChange event for the Scheduled Date text input
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChangeScheduledPostDate = (e) => {
        if (e) {
            if (e.target) { // if we're editing the input field manually, then handle the entry
                const val = e.target.value;
                let parsedDate = null;

                setPost(post => {
                    return { ...post, 'scheduledPostDate': val }
                });

                if (isValidDateTime(new Date(val))) {
                    parsedDate = setDateTime(scheduledDateTime, new Date(val));  // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
                    setIsDateTimeValid(true);
                    setScheduledDateTime(parsedDate);
                    setHasDataChanged(true);
                    setNotifyFormUpdated();
                } else {
                    setIsDateTimeValid(false);
                }
            } else { // if we're editing the input field via the calendar
                const val = e; // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
                let parsedDate = null;

                if (isValidDateTime(new Date(val))) {
                    parsedDate = setDateTime(scheduledDateTime, new Date(val));
                    setIsDateTimeValid(true);
                    setPost(post => {
                        return { ...post, 'scheduledPostDate': formatDate(parsedDate, 'dd-MMM-yyyy') }
                    });
                    setScheduledDateTime(parsedDate);
                    setHasDataChanged(true);
                    setNotifyFormUpdated();
                } else {
                    setIsDateTimeValid(false);
                }
            }
        }
    };

    /**
     * onChange event for the Scheduled Time text input
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChangeScheduledPostTime = (e) => {
        if (e) {
            if (e.target) { // if we're editing the input field manually, then handle the entry
                const val = e.target.value;
                let parsedDate = null;

                setPost(post => {
                    return { ...post, 'scheduledPostTime': val }
                });

                if (isValidTime(val)) {
                    parsedDate = addTimeToDate(val, scheduledDateTime);  // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
                    setIsDateTimeValid(true);
                    setScheduledDateTime(parsedDate);
                    setHasDataChanged(true);
                    setNotifyFormUpdated();
                } else {
                    setIsDateTimeValid(false);
                }
            } else { // if we're editing the input field via the calendar
                const val = e; // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
                let parsedDate = null;

                if (isValidDateTime(new Date(val))) {
                    parsedDate = new Date(val);
                    setIsDateTimeValid(true);
                    setPost(post => {
                        return { ...post, 'scheduledPostTime': formatDate(parsedDate, 'HH:mm') }
                    });
                    setScheduledDateTime(setDateTime(parsedDate, scheduledDateTime));
                    setHasDataChanged(true);
                    setNotifyFormUpdated();
                } else {
                    setIsDateTimeValid(false);
                }
            }
        }
    };

    /**
     * Cancels this form edit and goes back to social group page
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onCancel = (e) => {
        e.preventDefault();

        if (post.postStatuses && post.postStatuses[0] === config.postStatus.DRAFT) {
            onDeletePost();
        } else {
            router.history(router.query.ref || `/${post.applicationId}/social/${post.socialGroupId}/calendar`);
        }
    };

    /**
     * Deletes the post
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onDeletePost = async (e) => {
        if (e) e.preventDefault();

        const y = post.postStatuses && post.postStatuses[0] === config.postStatus.DRAFT ?
            window.confirm('Do you want to cancel this draft?\r\rClick OK to continue or Cancel to abort this action.')
            :
            window.confirm('Do you want to delete this post from Stitchz?\r\rThis action is irreversible.\r\rClick OK to continue or Cancel to abort this action.')
            ;

        if (y) {
            try {
                const { status, message } = await deletePost(post.applicationId, post.socialGroupId, post.postId)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    // onError(message);
                    setErrorOccurred('An Error Occurred Deleting this Post', message);
                } else {
                    router.history(router.query.ref || `/${post.applicationId}/social/${post.socialGroupId}/calendar`);
                }
            } catch (e) {
                // onError(e);
                setErrorOccurred('An Unexpected Error Occurred', e);
            }
        }

        return false;
    };

    /**
     * onClick mouse event to add the new social media post
     * @param {MouseEvent} event mouse click event
     * @returns {void}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const timeZoneOffset = new Date().getTimezoneOffset() / 60,
            scheduledTime = post.scheduledPostTime,
            scheduledDate = post.scheduledPostDate,
            scheduledPostDate = formatDate(addTimezoneOffset(setDateTime(scheduledTime, scheduledDate), timeZoneOffset), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // eslint-disable-line quotes

        let x = {
            ...post,
            'scheduledPostDate': scheduledPostDate,
            'activityType': config.activityType.MEDIA,
            'postStatus': config.postStatus.SCHEDULED
        };

        try {
            const { status, message } = await savePost(post.applicationId, post.socialGroupId, post.postId, x)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                // onError(message);
                setErrorOccurred('An Error Occurred Saving this Post', message);
            } else {
                router.history(router.query.ref || `/${post.applicationId}/social/${post.socialGroupId}/calendar`);
            }
        } catch (e) {
            setIsLoading(false);
            // onError(e);
            setErrorOccurred('An Unexpected Error Occurred', e);
        } finally {
            //
        }
    };

    /**
     * Toggles on/off the image upload modal dialog
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onAddImageClick = (e) => {
        e.preventDefault();

        setShowAddImageModal();
    };

    /**
     * Uploads the selected image file and sets the state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onAddImageModalChange = async (e) => {
        e.preventDefault();

        // change icon to indicate we're loading
        setLoadingImage(true);

        const file = e.target.files[0];
        let media;

        if (file.type === 'video/mp4') {
            media = document.createElement('video')
            media.addEventListener('loadedmetadata', async () => {
                // console.log(media.videoWidth, media.videoHeight);
                // const width = media.naturalWidth,
                //     height = media.naturalHeight;

                window.URL.revokeObjectURL(media.src);

                // TODO: check if video dimensions are supported

                await processUploadedMedia(file, {
                    height: media.videoHeight,
                    width: media.videoWidth,
                    format: file.type.split('/')[1]
                });
            });
            media.src = window.URL.createObjectURL(file);
        } else{
            const img = new Image();
            img.src = window.URL.createObjectURL(file);

            img.onload = async () => {
                const width = img.naturalWidth,
                    height = img.naturalHeight;

                window.URL.revokeObjectURL(img.src);

                if (width <= 5000 && height <= 15000) {
                    await processUploadedMedia(file, {
                        height: height,
                        width: width,
                        format: file.type.split('/')[1]
                    });
                } else {
                    setLoadingImage(false);
                    //fail
                    alert('Image dimensions are too big. The image must be less than 5000 wide by 15000 long.');
                }
            };
        }

        setShowAddImageModal();
    };

    /**
     * Reads in the file object and uploads it to the backend
     * @param {File} file media object to upload
     * @param {object} metadata collection of file properties
     */
    const processUploadedMedia = async (file, metadata) => {
        const fileInfo = {
                size: file.size,
                name: file.name,
                type: file.type,
                height:metadata.height,
                width:metadata.width,
                format:metadata.format
            },
            uploadResponse = await uploadMedia(post.applicationId, post.socialGroupId, post.socialPostCode, file, fileInfo)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                }),
            nextPost = { ...post };

        if (uploadResponse.status !== 'OK') {
            // onError(uploadResponse.message);
            setErrorOccurred('An Error Occurred Uploading Media', uploadResponse.message);
        } else {
            nextPost.mediaUrl = uploadResponse.body.publicFilePath;
            nextPost.media = {
                url: uploadResponse.body.publicFilePath,
                width: uploadResponse.body.width,
                height: uploadResponse.body.height,
                contentType: uploadResponse.body.contentType,
                size: uploadResponse.body.size
            };
            nextPost.media.words = uploadResponse.body.words;

            setLoadingImage(false);
            setPost(nextPost);
            setOpenAIImageWords(nextPost.media.words);
            setTotalCharacters(getTweetLength(nextPost.message, nextPost.shareUrl, nextPost.mediaUrl));
        }
    };

    /**
     * Remove the image object from this post's state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const removeMedia = async (e) => {
        if (e)
            e.preventDefault();

        const y = window.confirm('Do you want to remove this image?');

        if (y) {
            try {
                const nextPost = { ...post },
                    mediaId = nextPost.media.url.split('/').pop();

                delete nextPost.mediaUrl;
                if (nextPost.remoteSource) {
                    delete nextPost.remoteSource.localImageUrl; // TODO: send request to API to move this image to final location in S3 bucket and return public file path
                    delete nextPost.remoteSource.imageSourceUrl;
                    delete nextPost.remoteSource.imageTitle;
                }
                delete nextPost.media;

                // optimistic delete from state
                setPost(nextPost);

                // Send DELETE request to S3
                await deleteMedia(nextPost.applicationId, nextPost.socialGroupId, nextPost.postId, mediaId)
                    .catch(err => {
                        console.error(err);
                        setPost(post);
                    });
            } catch(err) {
                console.error(err);
                setPost(post);
            }
        }

        return false;
    };

    /**
     * Toggles on/off the remote source modal dialog
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onAddRemoteSourceClick = (e) => {
        e.preventDefault();

        setShowAddRemoteSourceModal();
    };

    /**
     * Show/hide remote source modal window
     * @returns {void}
     */
    const toggleSetShowRemoteSourceModal = () => {
        // if (showAddRemoteSourceModal && !post.remoteSource) {
        //     setRemoteSourceContent(null);
        // }

        setShowAddRemoteSourceModal();
    }

    /**
     * onChange event for the text input for the remote url search
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onAddRemoteSourceModalChange = (e) => {
        e.preventDefault();

        const val = e.target.value;

        setRemoteSourceUrl(val);
    };

    /**
     * Adds the share URL to the message block
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onRemoteLinkClick = (e) => {
        const node = nearestAncestorWithHref(e.target),
            shareUrl = node && node.href,
            message = `${post.message ? post.message : ''} ${shareUrl}`;

        // set the twitter character legnth
        setTotalCharacters(getTweetLength(message, '', post.mediaUrl));

        // set's the post's message value
        setPost(post => {
            return { ...post, 'message': message }
        });
    };

    /**
     * Requests the remote content and sets the remote source state to display the results
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onAddRemoteSourceButtonClick = async (e) => {
        e.preventDefault();

        setIsRemoteSourceLoading(true);

        try {
            console.log('Requesting...');
            const response = await getRemoteUrl(post.applicationId, post.socialGroupId, post.socialPostCode, remoteSourceUrl)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });
            // console.log(response.statusCode);
            // console.log(response.status);
            // console.log(response.body);

            // we're expecting a 202 to start the polling process
            if (response.statusCode === 202) {
                const requestId = response.body.requestId,
                    pollingEndpoint = `${config.apiGateway.URL}/app/${post.applicationId}/social/${post.socialGroupId}/post/${post.socialPostCode}/polling/${requestId}`,
                    successResponse = 200,
                    pollingInterval = 1000,
                    maxPollingDuration = 240000,
                    startTime = Date.now(); // Record the start time

                // wait the inital polling interval
                await new Promise(resolve => setTimeout(resolve, pollingInterval));

                // now start the polling
                requestStatus(pollingEndpoint, successResponse, pollingInterval, maxPollingDuration, startTime);
            }
        } catch (err) {
            setErrorOccurred('An Unexpected Error Occurred', err);
        }
    };

    /**
     * function call to wait for api to complete long running processing
     * @param {*} apiEndpoint the api endpoint to poll
     * @param {number} successResponse successful endpoint response
     * @param {number} pollingInterval how often to check
     * @param {Function} maxPollingDuration function to run when polling is complete
     * @param {Date} startTime the timestamp when the polling starts
     * @returns {object} json object of response
     */
    const requestStatus = async (apiEndpoint, successResponse, pollingInterval, maxPollingDuration, startTime) => {
        try {
            const result = await pollApi(apiEndpoint);

            if (result.statusCode === successResponse) {
                console.log(`Success response received in ${Date.now() - startTime}:`, result);
                if (result.contentType === 'application/json') {
                    setRemoteSourceContent(JSON.parse(result.body));
                } else {
                    setRemoteSourceContent(result.body);
                }
                setIsRemoteSourceLoading(false);
            } else if (result.statusCode >= 400) {
                const message = result.message,
                    statusCode = !isString(message) ? message.statusCode : result.statusCode,
                    body = result.contentType === 'application/json' ? JSON.parse(result.body) : result.body;
                console.log(body);
                // if (!isString(message)) {
                // console.error(message);
                if (statusCode && statusCode === 400) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>400 Bad Request</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided could not be understood by the server due to malformed syntax. Try to manually navigate to the URL for needed inforamtion' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 401) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>401 Unauthorized</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided requires user authentication. Try to manually navigate to the URL for needed inforamtion' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 403) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>403 Forbidden</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the server understood the request, but is refusing to fulfill it. Try a different URL.' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 404) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>404 Not Found</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided was not found' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 408) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>408 Request Timeout</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided took too long to respond.' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 429) {
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>429 Too Many Requests</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided has had too many requests, it is likely they are experiencing too much traffic. Try to manually navigate to the URL for needed inforamtion' }</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                } else if (statusCode && statusCode === 500) {
                    setRemoteSourceContent(<Alert variant='danger'><Alert.Heading>500 Internal Server Error</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided encountered an unexpected error and was unable to respond' }</p></Alert>);
                } else if (statusCode && statusCode === 502) {
                    setRemoteSourceContent(<Alert variant='danger'><Alert.Heading>502 Bad Gateway</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided was unavailable and was unable to respond' }</p></Alert>);
                } else if (statusCode && statusCode === 503) {
                    setRemoteSourceContent(<Alert variant='danger'><Alert.Heading>503 Service Unavailable</Alert.Heading><p>{ body && body.Error && body.Error.message ? body.Error.message : 'the URL provided received an invalid response from the upstream server it accessed in attempting to fulfill the request and was unable to respond' }</p></Alert>);
                }
                setIsRemoteSourceLoading(false);
                // } else {
                //     console.log(typeof message);
                //     console.log(message);
                //     setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>Notice</Alert.Heading><p>{message}</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);

                //     const nextPost = { ...post };

                //     nextPost.message = (nextPost.message ? `${nextPost.message} ` : '') + remoteSourceUrl;

                //     setPost(nextPost);
                // }
            } else {
                // check elapsed time
                const elapsedTime = Date.now() - startTime;

                if (elapsedTime < maxPollingDuration) {
                    // console.log(`Elapsed time ${elapsedTime} milliseconds`);
                    // console.log(`Poll again in ${pollingInterval} milliseconds`);
                    // wait for defined interval
                    await new Promise(resolve => setTimeout(resolve, pollingInterval));
                    requestStatus(apiEndpoint, successResponse, pollingInterval, maxPollingDuration, startTime);
                } else {
                    console.warn('Maximum polling duration reached. Stopping polling.');
                    console.warn(`Total time ${elapsedTime}`);
                    setRemoteSourceContent(<Alert variant='warning'><Alert.Heading>408 Request Timeout</Alert.Heading><p>the URL provided took too long to respond.</p><Alert.Link href={remoteSourceUrl} target='_blank' onClick={onRemoteLinkClick}>{remoteSourceUrl}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></Alert.Link></Alert>);
                    setIsRemoteSourceLoading(false);
                }
            }
        } catch(err) {
            console.error(err);
            setRemoteSourceContent(<Alert variant='danger'><Alert.Heading>500 Internal Server Error</Alert.Heading><p>the URL provided encountered an unexpected error and was unable to respond</p></Alert>);
            setIsRemoteSourceLoading(false);
        }
    };

    /**
     * Requests the remote content and sets the remote source state to display the results
     * @returns {void}
     */
    const onSendChatMessage = async () => {
        try {
            setIsChatGPTLoading(true);

            const { status, message, body } = await sendChatGPTMessage(openAIWords)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                // onError(message);
                setErrorOccurred('An Error Occurred Talking to Open AI', message);
            } else {
                // setShowChatGPTModal(false);
                setChatGPTResponse(body);
                // // setChatGPTConversation('' + body);
                // const val = (post.message ? post.message : '') + body,
                //     field = 'message';
                // setHasDataChanged(true);
                // setNotifyFormUpdated();
                // count Twitter characters
                // setTotalCharacters(getTweetLength(val, post.shareUrl, post.mediaUrl));

                // setPost(post => {
                //     return { ...post, [field]: val }
                // });
            }
        } catch (err) {
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        } finally {
            setIsChatGPTLoading(false);
        }
    };

    /**
     * Requests the remote content and sets the remote source state to display the results
     * @returns {void}
     */
    const onSendChatImageMessage = async () => {
        try {
            setIsChatImageGPTLoading(true);

            const { status, message, body } = await sendChatGPTMessage(openAIImageWords)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                // onError(message);
                setErrorOccurred('An Error Occurred Talking to Open AI', message);
            } else {
                setChatGPTResponse(body);
            }
        } catch (err) {
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        } finally {
            setIsChatImageGPTLoading(false);
        }
    };

    /**
     * When the Chat GPT message is clicked, select it to use for this post
     */
    const onSelectChatGPTMessage = async () => {
        setShowImageChatGPTModal(false);
        const val = (post.message ? post.message : '') + chatGPTResponse,
            field = 'message';
        setHasDataChanged(true);
        setNotifyFormUpdated();
        // count Twitter characters
        setTotalCharacters(getTweetLength(val, post.shareUrl, post.mediaUrl));

        setPost(post => {
            return { ...post, [field]: val }
        });
    };

    /**
     * Toggles whether to include the image with the remote source
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onTickNoImage = (e) => {
        const field = e.target,
            isChecked = field.checked;

        setIncludeImage(!isChecked);

        if (remoteSourceContent) {
            const checkboxes = document.querySelectorAll('div.remote-image-tick input[type="checkbox"]');

            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[`${i}`].checked = isChecked;
            }
        }
    };

    /**
     * Selects the remoteSource object and adds it to the post's state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onSelectRemoteSource = async (e) => {
        if (e)
            e.preventDefault();

        const node = nearestAncestorWithClassname(e.target, ['remote-title', 'remote-image']),
            id = node && node.id;

        setLoadingRemote(id);

        const source = remoteSourceContent.find(s => {
            return s.id === id;
        });

        if (source) {
            const nextPost = { ...post };

            if (!nextPost.message) {
                nextPost.message = source.sourceTitle;
            }

            nextPost.shareUrl = source.sourceUrl || source.sourceAttributionUrl;
            nextPost.remoteSource = {
                sourceTitle: source.sourceTitle,
                sourceUrl: source.sourceUrl || source.sourceAttributionUrl,
                sourceAttribution: source.sourceAttribution,
                sourceAttributionUrl: source.sourceAttributionUrl,
                sourceImageUrl: source.sourceImageUrl,
                sourceDescription: source.sourceDescription
            };

            if (includeImage) {
                // send request to API to move this image to final location in S3 bucket and return public file path
                const { status, message, body } = await selectRemoteImage(post.applicationId, post.socialGroupId, post.socialPostCode, source.publicFilePath, nextPost.remoteSource, false)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    // onError(message);
                    setErrorOccurred('An Error Occurred Selecting a Remote Source', message);
                } else {
                    nextPost.mediaUrl = source.imageSourceUrl;
                    nextPost.remoteSource.localImageUrl = source.publicFilePath;
                    nextPost.remoteSource.imageSourceUrl = source.imageSourceUrl;
                    nextPost.remoteSource.imageTitle = source.imageTitle;
                    nextPost.media = {
                        url: body ? body.publicFilePath : source.publicFilePath,
                        title: source.sourceTitle,
                        width: source.width,
                        height: source.height,
                        contentType: source.contentType,
                        size: source.size
                    };
                    nextPost.media.words = body.words;
                    setOpenAIImageWords(body.words);
                }
            }

            setPost(nextPost);
            setTotalCharacters(getTweetLength(nextPost.message, nextPost.shareUrl, nextPost.mediaUrl));
            toggleSetShowRemoteSourceModal();
            scrollTop();
            setLoadingRemote(null);
        }

        return false;
    };

    /**
     * Remove the remoteSource object from this post's state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const removeRemoteSource = (e) => {
        if (e)
            e.preventDefault();

        const y = window.confirm('Do you want to remove this remote link?');

        if (y) {
            const nextPost = { ...post };

            delete nextPost.remoteSource;
            delete nextPost.shareUrl;

            setPost(nextPost);
        }

        return false;
    };

    /**
     * Render the html for the video block
     * @param {object} media media object containing the media definition
     * @returns {HTMLElement} html block with video features
     */
    const renderVideoPlayer = (media) => {
        return(
            <div className="player">
                <video controls width="100%">
                    <source src={media.url} type="video/mp4" />
                </video>
            </div>
        );
    };

    /**
     * Build and display the UI for the uploaded media
     * @param {object} media object containing media datails
     * @returns {ReactComponentElement|void} html building out the uploaded media or nothing
     */
    const renderMediaAttachment = (media) => {
        if (media && Object.keys(media).length > 0) {
            const mediaUrl = new URL(media.url),
                ext = media.url.split('.').pop();

            let mediaBlock = media.contentType === 'video/mp4' ? renderVideoPlayer(media)
                :
                <img src={media.url} width='150' height='150' alt='media attachment' className='remote-image img-thumbnail card-img-top' />;

            if (mediaUrl.pathname.endsWith('.svg')) {
                mediaBlock = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150' width='150' height='150' alt='remote attachment' className='remote-image img-thumbnail card-img-top'>
                    <use href={media.url} />
                </svg>;
            }

            return (
                <Card style={{ padding: '0.5rem' }}>
                    <Row className='remote-image-container'>
                        <Col sm={12} md={10}>
                            {mediaBlock}
                        </Col>
                        <Col sm={12} md={2}>
                            <Button variant='link' className='close-local-image-content' onClick={removeMedia}>X</Button>
                        </Col>
                    </Row>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Card.Title style={{ fontSize: '0.75rem' }}>{media.title}</Card.Title>
                                <Card.Text style={{ fontSize: '0.75rem' }}>Size (H x W): {media.height} x {media.width}</Card.Text>
                                <Card.Text style={{ fontSize: '0.75rem' }}>Aspect ratio: {(media.height / media.width).toFixed(2)}</Card.Text>
                                <Card.Text style={{ fontSize: '0.75rem' }}>File Size: { fileSizeDiplay(media.size) }</Card.Text>
                                <Card.Text style={{ fontSize: '0.75rem' }}>File Ext: {ext}</Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            );
        }
    };

    /**
     * Build and display the UI for the remote source
     * @param {object} remote object containing details of the remote source
     * @returns {ReactComponentElement|void} html building out the remote source UI or nothing
     */
    const renderRemoteSourceContent = (remote) => {
        if (remote && Object.keys(remote).length > 0) {
            return (
                <Row className='remote-link'>
                    <Col sm={12} md={9} className='remote-link-content'>
                        <div className='remote-title'>{remote.sourceTitle}</div>
                        <div className='remote-url'><a href={remote.sourceUrl}>{remote.sourceUrl}</a></div>
                        <div className='remote-body'>{remote.sourceDescription}</div>
                    </Col>
                    <Col sm={12} md={3}>
                        <Button variant='link' className='close-remote-link-content' onClick={removeRemoteSource}>X</Button>
                    </Col>
                </Row>
            );
        }
    };

    /**
     * Returns the identity object associated with the post
     * @param {string} id unique id for the identity account
     * @returns {object} identity object
     */
    const getIdentity = (id) => {
        return socialGroup && socialGroup.socialGroupIdentitys && socialGroup.socialGroupIdentitys.find(s => {
            return s.id === id;
        });
    };

    /**
     * onClick mouse event to start the Identity request process
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onClickAddIdentity = async (e) => {
        e.preventDefault();

        setIsRefreshing(true);

        const target = nearestAncestorWithId(e.target),
            prov = config.providers.find(p => {
                return p.id === Number(target.id);
            }),
            reqBody = {
                state: await generateRandomString(8, 'hex')
                    .catch(err => {
                        console.error(err.message);
                        return null;
                    }),
                oauthScope: prov && prov.canManageScope ? prov.scope : '',
                providerId: target.id,
                requestorUrl: `${config.BASEURL}app/${post.applicationId}/social/${router.query.grpId}`
            };

        await sendTokenRequest(post.applicationId, post.socialGroupId, reqBody);
    };

    /**
     * Send the POST request initiating the identity token request
     * @param {string} appId the post's application Id
     * @param {string} socialGroupId the post's social group Id
     * @param {object} reqBody the body to send with the POST request
     * @returns {void}
     */
    const sendTokenRequest = async (appId, socialGroupId, reqBody) => {
        try {
            const { status, message, body } = await initiateNewIdentityRequest(appId, socialGroupId, reqBody)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setErrorOccurred('An Error Occurred Adding an Identity', message);
            } else {
                // redirect to IdP authentication page
                window.location = body.redirectUrl;
            }
        } catch (err) {
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        } finally {
            setIsRefreshing(false);
        }
    }

    /**
     * onClick mouse event to start the Identity token renewal process
     * @param {object} iden current identity object
     * @returns {void}
     */
    const onClickRenewPageToken = async (iden) => {
        setIsRefreshing(true);

        let page;

        // get the parent identity of the current page
        const parentIdentity = socialGroup.socialGroupIdentitys.find(sgi => { // eslint-disable-line security/detect-object-injection
            return sgi.id === iden.parentIdentityId;
        });

        if (!parentIdentity) {
            // onError('No Parent Identity Found.');
            setErrorOccurred('No Parent Identity Found', `"${iden.displayName || iden.preferredUsername}'s" parent account, "${iden.parentIdentityId}", was not found. Contact Stitchz Support for help.`);
            return;
        }

        // CHECK HERE IF parentIdentity has expired, if so, then send down initiateNewIdentityRequest() process
        if (parentIdentity.accessTokenExpirationUtc
            && isAfterDate(new Date(), parentIdentity.accessTokenExpirationUtc)
            && post.postStatus !== config.postStatus.POSTED) {
            if (confirm('This account\'s Parent Identity Token has expired, you must first refresh the parent\'s token in order to get a new Page token.')) {
                const prov = config.providers.find(p => {
                        return p.id === Number(parentIdentity.providerId);
                    }),
                    reqBody = {
                        state: await generateRandomString(8, 'hex')
                            .catch(err => {
                                console.error(err.message);
                                return null;
                            }),
                        oauthScope: prov ? prov.canManageScope ? prov.scope : 'email' : '',
                        providerId: parentIdentity.providerId,
                        requestorUrl: `${config.BASEURL}app/${post.applicationId}/social/${post.socialGroupId}`
                    };

                // TODO: follow the below by updating all child identities
                await sendTokenRequest(post.applicationId, post.socialGroupId, reqBody);
            }
            setIsRefreshing(false);
        } else {

            const { status, message, body } = await getPagesCompaniesBoards(post.applicationId, socialGroup.id, parentIdentity.id, parentIdentity.providerId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                // onError(message);
                setErrorOccurred('An Error Occurred Getting Boards', message);
            } else {
                console.log(body);
                if (body.data) {
                    page = body.data.find(p => {
                        return p.id === iden.idpId;
                    });
                } else {
                    page = body.elements.find(p => {
                        return p['organization~'] && p['organization~'].id === Number(id);
                    });
                }

                // linkedin's identifier is the organization id
                if (page.organization) {
                    page.identity = page.organization;
                }
            }

            const addPageResponse = await addPageCompanyIdentity(post.applicationId, socialGroup.id, parentIdentity, page)
                .catch(err => {
                    return {
                        status2: 'failed',
                        message2: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (addPageResponse.status !== 'OK') {
                // onError(addPageResponse.message);
                setErrorOccurred('An Error Occurred While Adding a Page', addPageResponse.message);
            } else {
                // get the indices of the respective group and identity
                const socialGroupLocal = { ...socialGroup },
                    socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys],
                    existingIdentity = socialGroupIdentitysLocal.findIndex(i => { return i.identity === addPageResponse.body.identity });

                // check for an existing identity, if one is found then replace it in the state
                if (existingIdentity > -1) {
                    socialGroupIdentitysLocal[`${existingIdentity}`] = addPageResponse.body;
                    socialGroupLocal.socialGroupIdentitys = socialGroupIdentitysLocal;

                    // update the social group state
                    setSocialGroup(socialGroupLocal);
                }
            }

            setIsRefreshing(false);
        }
    };

    /**
     * Handle the drag and drop 'dropped' event
     * @param {object} source identity to reorder
     * @param {object} identity identity to replace
     * @returns {boolean} whether or not the change completed successfully
     */
    const onChangeOrder = async (source, identity) => {
        // find the identity in question
        const nextSocialGroup = { ...socialGroup },
            nextIdentitys = [...nextSocialGroup.socialGroupIdentitys],
            prevSocialGroup = { ...socialGroup },
            sourceIdentityIndex = nextIdentitys.findIndex(c => {
                return c.id === source.identityId;
            });

        let destinationIdentityIndex = nextIdentitys.findIndex(c => {
            return c.id === identity.id;
        });

        // if there is no destination identity then assume we want to move to the end of the array
        if (destinationIdentityIndex < 0) {
            destinationIdentityIndex = nextIdentitys.length;
        }

        // if we dropped over ourselves then ignore
        if (sourceIdentityIndex === destinationIdentityIndex) {
            return;
        }

        // optimistic reorder
        nextIdentitys.splice(destinationIdentityIndex, 0, nextIdentitys.splice(sourceIdentityIndex, 1)[0]);

        nextSocialGroup.socialGroupIdentitys = nextIdentitys;

        // update the state
        setSocialGroup(nextSocialGroup);

        // now save the new order
        try {
            const { status, message } = await reorderIdentitys(post.applicationId, post.socialGroupId, source.identityId, identity.id)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                // onError(message);
                setErrorOccurred('An Error Occurred Reordering Identities', message);

                // restore old identity order
                setSocialGroup(prevSocialGroup);
            }
        } catch (err) {
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        }

        return false;
    };

    /**
     * Returns the text value of the post status number
     * @returns {string} post status text
     */
    const getPostStatus = () => {
        for (var key in config.postStatus) {
            if (config.postStatus.hasOwnProperty(key)) {
                if (config.postStatus[`${key}`] === post.postStatus) {
                    return key;
                }
            }
        }
        return 'unknown';
    };

    /**
     * Returns the text value of the activity type number
     * @returns {string} activity type text
     */
    const getActivityType = () => {
        for (var key in config.activityType) {
            if (config.activityType.hasOwnProperty(key)) {
                if (config.activityType[`${key}`] === post.activityType) {
                    return key;
                }
            }
        }
        return 'unknown';
    };

    /**
     * Show/hide Chat GPT modal window
     * @returns {void}
     */
    const toggleSetShowChatGPTModal = () => {
        setChatGPTResponse(null);
        setShowChatGPTModal();
    };

    /**
     * onChange event for the text input for the chat gpt interface
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onAddChatGPTMessage = (e) => {
        e.preventDefault();

        const val = e.target.value;

        setOpenAIWords(val);
    };

    /**
     * Show/hide Chat GPT modal window based on image attached
     * @returns {void}
     */
    const toggleSetShowImageChatGPTModal = () => {
        setChatGPTResponse(null);
        setShowImageChatGPTModal();
    };

    /**
     * onChange event for the text input for the chat gpt interface based on image attached
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onAddImageChatGPTMessage = (e) => {
        e.preventDefault();

        const val = e.target.value;

        setOpenAIImageWords(val);
    };

    /**
     * Build and display the post info section
     * @returns {HTMLElement} html post section
     */
    const renderPostInfoPanel = () => {
        const remoteUrlSource = post && post.remoteUrlSource ?
                <Row>
                    <Col>
                        <strong>Remote Source Details</strong>
                        <div>
                            Source: {post.remoteUrlSource.SourceUrl}
                        </div>
                    </Col>
                </Row> : '',
            filePanel = post && post.fileNames ?
                <Row>
                    <Col>
                        {post.fileNames.map(file => {
                            return <>
                                <strong>Image Details</strong>
                                <div>Name: {file.fileName}</div>
                                <div>Type: {file.mimeType}</div>
                                <div>H x W: {file.imgHeight} X {file.imgWidth} ({(file.imgHeight / file.imgWidth).toFixed(2)})</div>
                                <div>Size: {file.fileSize / 1024} Kb</div>
                            </>
                        })}
                    </Col>
                </Row> : '';

        return post &&
            <Alert variant='secondary' className='smaller'>
                <Row>
                    <Col>
                        <strong>Status</strong> <span>{getPostStatus()}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Schedule Date</strong>
                        <div className='date-posted' title='scheduled post date'>
                            <time>{post.scheduledPostDate} {post.scheduledPostTime}</time>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Posted Date</strong>
                        <div className='date-posted' title='scheduled post date'>
                            <time>{post.postDate}</time>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Post Type</strong> <span>{getActivityType()}</span>
                    </Col>
                </Row>
                {remoteUrlSource}
                {filePanel}
                <Row>
                    <Col>
                        <strong>Created by</strong> <span>{post && post.addedBy && post.addedBy.Username ? post.addedBy.Username : post.addedBy} on {post && formatDate(post.addedAt, 'dd-MMM-yyyy HH:mm')}</span>
                    </Col>
                </Row>
            </Alert>
        ;
    };

    /**
     * Build and display the post Error info section
     * @returns {HTMLElement} html error section
     */
    const renderErrorInfo = () => {
        if (post && post.postStatus === config.postStatus.ERRORED) {
            let title = getErrorMessageTitle(post.Error, post.ErrorMessage),
                textMsg = getErrorMessageBody(post.ErrorMessage);

            return (
                <Card
                    bg='danger'
                    text='white'
                    className='mb-2'
                >
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>
                            {textMsg}
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else {
            return '';
        }
    };
    // console.log(post);
    return (
        <DndProvider backend={HTML5Backend}>
            <div className='App'>
                {pageLoading || !post ?
                    <Loading pageLoading={pageLoading} /> :
                    <Container>
                        <Suspense fallback={<Loading pageLoading={true} />}>
                            <AddImageModal
                                show={showAddImageModal}
                                handleClose={setShowAddImageModal}
                                onChange={onAddImageModalChange}
                            />
                        </Suspense>
                        <Suspense fallback={<Loading pageLoading={true} />}>
                            <AddRemoteSourceModal
                                show={showAddRemoteSourceModal}
                                handleClose={toggleSetShowRemoteSourceModal}
                                remoteSourceUrl={remoteSourceUrl}
                                onChange={onAddRemoteSourceModalChange}
                                isLoading={isRemoteSourceLoading}
                                onGetRemoteUrl={onAddRemoteSourceButtonClick}
                                remoteContent={remoteSourceContent}
                                onSelect={onSelectRemoteSource}
                                onTick={onTickNoImage}
                                isLoadingRemote={loadingRemote}
                            />
                        </Suspense>
                        <Suspense>
                            <KeywordsChatGPTBlock
                                show={showChatGPTModal}
                                handleClose={toggleSetShowChatGPTModal}
                                onChange={onAddChatGPTMessage}
                                chatGPTMessage={openAIWords}
                                isLoading={isChatGPTLoading}
                                onSendChatMessage={onSendChatMessage}
                                chatGPTResponse={chatGPTResponse}
                                handleClick={onSelectChatGPTMessage}
                            />
                        </Suspense>
                        <Suspense>
                            <ImageChatGPTBlock
                                show={showImageChatGPTModal}
                                handleClose={toggleSetShowImageChatGPTModal}
                                onChange={onAddImageChatGPTMessage}
                                chatGPTMessage={openAIImageWords}
                                isLoading={isChatImageGPTLoading}
                                onSendChatMessage={onSendChatImageMessage}
                                chatGPTResponse={chatGPTResponse}
                                handleClick={onSelectChatGPTMessage}
                            />
                        </Suspense>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='label'><h2>Social Media Sharing (Edit)</h2></Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} sm={6}>
                                <div className='text-right'>
                                    Pick a social profile to share a post to here <FontAwesomeIcon icon={faLevelDownAlt} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} sm={6}>
                                <PostIdentityCards
                                    post={post}
                                    socialGroup={socialGroup}
                                    identity={getIdentity(post.identityId)}
                                    onClickAddIdentity={onClickAddIdentity}
                                    onClickRenewPageToken={onClickRenewPageToken}
                                    isRefreshing={isRefreshing}
                                    onChange={onChange}
                                    onSelectProfile={() => { }}
                                    onChangeOrder={onChangeOrder}
                                />
                                {renderPostInfoPanel()}
                                {renderErrorInfo()}
                            </Col>
                            <Col md={8} sm={6}>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Navbar className="ai-assistant-block">
                                                <Container fluid>
                                                    <NavDropdown
                                                        id="dropdown-basic"
                                                        title={
                                                            <><img src='/static/media/ai.png' height='28' width='28' /> AI Assistant</>
                                                        }
                                                    >
                                                        <NavDropdown.Item onClick={toggleSetShowChatGPTModal}>Suggest with AI</NavDropdown.Item>
                                                        <NavDropdown.Item onClick={toggleSetShowImageChatGPTModal} disabled={post.mediaUrl ? false : true}>Suggest based on Image</NavDropdown.Item>
                                                    </NavDropdown>
                                                </Container>
                                            </Navbar>
                                            <Form.Group controlId='message'>
                                                <Form.Control
                                                    value={post.message}
                                                    as='textarea'
                                                    rows='8'
                                                    onChange={onChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} className='remote-link'>
                                            <Row>
                                                <Col className='image-container'>
                                                    {post.media && renderMediaAttachment(post.media)}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>
                                                    <Button variant='link' href='#' id='media-modal' onClick={onAddImageClick} className={post.image && Object.keys(post.image).length > 0 ? 'disabled' : ''}>
                                                        {!loadingImage ?
                                                            <FontAwesomeIcon icon={faCameraRetro} size='2x' />
                                                            :
                                                            <FontAwesomeIcon icon={faRedo} size='2x' className='fa-spinner fa-spin' />
                                                        }
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button variant='link' href='#' id='link-modal' onClick={onAddRemoteSourceClick} className={post.remoteSource && Object.keys(post.remoteSource).length > 0 ? 'disabled' : ''}>
                                                        <FontAwesomeIcon icon={faLink} size='2x' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col className='remote-link-container'>
                                                    {post && post.remoteSource && renderRemoteSourceContent(post.remoteSource)}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={2}>
                                            <span className='message-length'>{totalCharacters}</span>
                                        </Col>
                                    </Row>
                                    <DateTimePicker
                                        displayDate={post.scheduledPostDate}
                                        displayTime={post.scheduledPostTime}
                                        calendarDate={scheduledDateTime}
                                        onChangeScheduledPostDate={onChangeScheduledPostDate}
                                        onChangeScheduledPostTime={onChangeScheduledPostTime}
                                        dateHasFocus={dateHasFocus}
                                        setDateHasFocus={setDateHasFocus}
                                        timeHasFocus={timeHasFocus}
                                        setTimeHasFocus={setTimeHasFocus}
                                    />
                                    <Row>
                                        <Col className='text-right'>
                                            <ButtonGroup aria-label='Schedule Post'>
                                                <LoaderButton
                                                    variant='success'
                                                    isLoading={isLoading}
                                                    disabled={!validateForm()}
                                                    type='submit'
                                                    onClick={handleSubmit}
                                                >
                                                    <FontAwesomeIcon icon={faSave} />&nbsp;Save
                                                </LoaderButton>
                                                <ButtonGroup>
                                                    <Button
                                                        variant='danger'
                                                        onClick={onDeletePost}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />&nbsp;Delete
                                                    </Button>
                                                </ButtonGroup>
                                                <ButtonGroup>
                                                    <Button
                                                        variant='warning'
                                                        onClick={onCancel}
                                                    >
                                                        <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Cancel
                                                    </Button>
                                                </ButtonGroup>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='shared-post-content-end-user-note'>
                                                <div className='smaller panel'>
                                                    <strong>Note</strong><br />
                                                    * All posts are associated with a time limited unique token. Scheduling a post too far into
                                                    the future could cause the post to fail if the token&apos;s lifespan has exceeded. Logging into
                                                    Stitchz.net regularly with the associated account will prevent a post failure.
                                                    <br />
                                                    <br />
                                                    ** Image dimensions cannot be larger than 15000(h)x5000(w) or 3 MB in size
                                                    <br />
                                                    <br />
                                                    {post && post.providerId === 15 ?
                                                        <>
                                                            *** Instagram requires an aspect ratio of 1.25 (5:4) or 1.91 (1.91:1)
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Instagram posts only support JPG files
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                }
                { errorMessage ?
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <ErrorModal message={errorMessage} show={showErrorModal} handleClose={showErrorMessageModal} />
                    </Suspense>
                    : ''
                }
            </div>
        </DndProvider>
    );
}
